@import "~ngx-toastr/toastr";
@import "intl-tel-input/build/css/intlTelInput.css";
body {
    overflow-x: hidden;
}

.confirm-btn-container>button {
    width: 100% !important;
}

label.star {
    color: white !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.bg-primary-custom {
    background-color: #242965 !important;
    color: white !important;
}

.badge-secondary-custom {
    background-color: #890137 !important;
    color: white !important;
}

input.star-5:checked~label.star:before {
    color: #e7a91c !important;
    text-shadow: none !important;
}

.btn-link,
a {
    text-decoration: none !important;
}

.item-custom {
    position: relative !important;
    background-color: #232f3e !important;
}

.multiselect-item-checkbox-div {
    color: #ffffff !important;
}

.multiselect-item-checkbox-div:hover {
    color: #1e2125 !important;
}

.overflow-wrap {
    overflow-wrap: break-word !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item{
    max-width: 100% !important;
    margin-bottom: 4px !important;
}
.popover .popover-header h3 {
    color: black !important
}

.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

